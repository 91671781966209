import React from "react";

import './contact.css'

function Contact() {


  return (
    <div classNameName="container-center">
        <div className="container">
          <div className="row">
              <h3>CONTACT</h3>
          </div>
          <div className="row">
 
          </div>
          <div className="row input-container">
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <input type="text" required />
                  <label>Name</label> 
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <input type="text" required />
                  <label>Email</label> 
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <textarea required></textarea>
                  <label>Message</label>
                </div>
              </div>
              <div className="col-xs-12">
                <center>
                <div className="btn-lrg submit-btn">Send Message</div>
                <p>currently unavailable</p>
                </center>
              </div>
          </div>
        </div>
    </div> 
  );
}

export default Contact;