import React from "react";
import Countdown from 'react-countdown';

function Shop() {
  return (
    <div className="container-center">
        <div className='login-container'>
            <h2>
                SHOP
            </h2>
            <p>
                <Countdown date={new Date('January 01, 2022 20:00:00')} />  
            </p>
     
        </div>
    </div> 
  );
}

export default Shop;