import React from "react";

import './login.css'


function Login() {
  return (
    <div classNameName="container-center">
        <div className="container">
          <div className="row">
              <h3>LOGIN</h3>
          </div>
          <div className="row">
 
          </div>
          <div className="row input-container">
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <input type="text" required />
                  <label>Email</label> 
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <input type="text" required />
                  <label>Password</label> 
                </div>
              </div>

              <div className="col-xs-12">
                <center>
                <div className="btn-lrg submit-btn">Login</div>
                <p>currently unavailable</p>
                </center>
              </div>
          </div>
        </div>
    </div> 
  );
}

export default Login;
