import React from "react";
import './background.css'

function Background() {
  return (
      <div style={{"opacity":"20%"}}>
          <div class="stars"></div>
          <div class="twinkling"></div>
      </div>
  );
}

export default Background
