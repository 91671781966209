import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import './return.css'

const Return = () => {
    const history = useHistory()
    const location = useLocation()

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if(location.pathname == '/') {
            setVisible(false)
        } else {
            setVisible(true)
        }
    }, [location])

    return (
        visible?
        <div className='return-container' onClick={() => {history.goBack()}}>
            {'back'}
        </div>:null
    )
}

export default Return;