import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


//pages/components
import Home from "./components/Pages/Home";
import Login from "./components/Pages/Login";
import Shipping from "./components/Pages/Shipping";
import Shop from "./components/Pages/Shop";
import Team from "./components/Pages/Team"; 
import Media from "./components/Pages/Media"; 
import Contact from "./components/Pages/Contact"; 

import Return from "./components/Return";



function App() {
  return (
    <div className="App">
      <Router>
        <Return/>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/shipping-info' component={Shipping}/>
          <Route exact path='/shop' component={Shop}/>
          <Route exact path='/team' component={Team}/>
          <Route exact path='/media' component={Media}/>
          <Route exact path='/contact' component={Contact}/>
        </Switch>

      </Router>
    </div>
  );
}

export default App;
