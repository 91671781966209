import React from "react";
import { useHistory } from "react-router";

function Navbar() {
    const history = useHistory()
    return (
        <div className="links">
            <div className="wrapper">
                <div
                    className="link" 
                    onClick={() => history.push('/team')}
                >
                    TEAM
                </div>
                <div
                    className="link" 
                    onClick={() => history.push('/shop')}
                >
                    SHOP
                </div>
                <div
                    className="link" 
                    onClick={() => history.push('/media')}
                >
                    MEDIA
                </div>
                <div
                    className="link" 
                    onClick={() => history.push('/shipping-info')}
                >
                    SHIPPING INFO
                </div>
                <div
                    className="link" 
                    onClick={() => history.push('/contact')}
                >
                    CONTACT
                </div>
                <div
                    className="link" 
                    onClick={() => history.push('/login')}
                >
                    LOGIN
                </div>
            </div>
        </div>
  );
}

export default Navbar;
