import React from "react";
import Countdown from 'react-countdown';
//assets
import Logo from '../../assets/logo.png'

//compon
import Navbar from "../Nav";

function Home() {
  return (
    <div className="container-center">
        <div className="wrapper">
        <img className="logo" src={Logo}/>
        <Navbar/>
        <Countdown date={new Date('January 01, 2022 20:00:00')} />
        </div>
    </div> 
  );
}

export default Home;
