import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Background from './assets/background/Background';
import './assets/index.css';
import Overlay from './assets/overlay/Overlay';

//render in effect layers
ReactDOM.render(
  <React.StrictMode>
    <Overlay/>
    <App />
    <div className="background-wrapper">
      <Background/>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
